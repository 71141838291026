.navigation-links-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.navigation-links-link {
  transition: 0.3s;
  text-decoration: none;
}
.navigation-links-link:hover {
  color: var(--dl-color-gray-500);
  font-size: 20px;
}
.navigation-links-link1 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link1:hover {
  color: var(--dl-color-gray-500);
  font-size: 20px;
}
.navigation-links-link2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link2:hover {
  color: var(--dl-color-gray-500);
  font-size: 20px;
}
.navigation-links-link3 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navigation-links-link3:hover {
  color: var(--dl-color-gray-500);
  font-size: 20px;
}








@media(max-width: 767px) {
  .navigation-links-nav {
    align-items: flex-start;
    flex-direction: row;
  }
  .navigation-links-link1 {
    margin-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
  }
  .navigation-links-link2 {
    margin-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
  }
  .navigation-links-link3 {
    margin-left: var(--dl-space-space-twounits);
    margin-bottom: 0px;
  }
}
@media(max-width: 479px) {
  .navigation-links-nav {
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .navigation-links-link {
    font-size: 14px;
    margin-bottom: 0px;
  }
  .navigation-links-link1 {
    font-size: 14px;
    margin-left: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
  }
  .navigation-links-link2 {
    font-size: 14px;
    margin-left: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
  }
  .navigation-links-link3 {
    font-size: 14px;
    margin-left: var(--dl-space-space-halfunit);
    margin-bottom: 0px;
  }
}
